export default {
    namespaced: true,
    state() {
        let platform = null;
        const platformMeta = document.querySelector('meta[name="cmnty-platform"]');
        if (platformMeta) {
            platform = JSON.parse(platformMeta.content);
        }

        return {
            platform,
        };
    },
    getters: {
        getPlatformVersion: (state) => (state.platform ? state.platform.version : null),
        getPlatformCode: (state) => (state.platform ? state.platform.code : null),
    },
};
