import moment from 'moment';
import TranslationStorage from './TranslationStorage';
import Translator from './Translator';
import i18nStore from './LocalizationStore';

export default {
    install(Vue, { locale, store, namespace = 'i18n' }) {
        store.registerModule(namespace, i18nStore);
        const translationStorage = new TranslationStorage(locale, store, namespace);
        translationStorage.seedStore();

        const translator = new Translator(translationStorage);

        // Global methods / properties.
        // Use as Vue.translator
        // eslint-disable-next-line no-param-reassign
        Vue.translator = translator;

        // Instance methods / properties
        // Use withing vue components as this.$translator
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$translator = translator;

        const $trans = (key, parameters = {}, domain = 'frontend', shouldEscapeHTML = true) => translator.trans(key, parameters, domain, shouldEscapeHTML);
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$trans = $trans;
        Vue.filter('trans', $trans);
        // eslint-disable-next-line no-param-reassign
        Vue.$trans = $trans;

        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$transAsync = async (key, parameters = {}, domain = 'frontend') => {
            await translator.load(domain);

            return translator.trans(key, parameters, domain);
        };

        const $transChoice = (key, amount, parameters = {}, domain = 'frontend') => translator.transChoice(key, amount, parameters, domain);
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$transChoice = $transChoice;
        Vue.filter('transChoice', $transChoice);

        moment.locale(locale);
        const $toRelativeDate = (date) => moment(date).fromNow();
        const $toAbsoluteDate = (date) => moment(date).format(`${store.getters['configuration/getSetting']('dateFormat')}, ${store.getters['configuration/getSetting']('timeFormat')}`);
        const $toAbsoluteDateWithoutTime = (date) => moment(date).format(`${store.getters['configuration/getSetting']('dateFormat')}`);
        const $toAbsoluteUtcDateWithoutTime = (date) => moment.utc(date).format(`${store.getters['configuration/getSetting']('dateFormat')}`);
        const $toAbsoluteTime = (date) => moment(date).format(store.getters['configuration/getSetting']('timeFormat'));
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$toRelativeDate = $toRelativeDate;
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$toAbsoluteDate = $toAbsoluteDate;
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$toAbsoluteDateWithoutTime = $toAbsoluteDateWithoutTime;
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$toAbsoluteUtcDateWithoutTime = $toAbsoluteUtcDateWithoutTime;
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$toAbsoluteTime = $toAbsoluteTime;
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$moment = moment;
        Vue.filter('toRelativeDate', $toRelativeDate);
        Vue.filter('toAbsoluteDate', $toAbsoluteDate);
    },
};
