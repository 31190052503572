<template>
    <div
        v-click-outside="testingMethod"
        class="heat-map-marker"
        :class="[{ 'heat-map-marker--focused': focused }, `heat-map-marker--${typeIndex}`]"
        :style="position"
        @mouseleave="debounceSetFocus(false)"
    >
        <div ref="marker" class="heat-map-marker__wrapper" @mouseenter="debounceSetFocus(true)">
            <span class="heat-map-marker__icon-wrapper">
                <span class="heat-map-marker__icon-inner-wrapper">
                    <font-awesome-icon class="heat-map-marker__icon heat-map-marker__icon--background" :icon="['fas', 'map-marker']" />
                    <font-awesome-icon class="heat-map-marker__icon heat-map-marker__icon" :icon="['fas', 'map-marker']" />
                    <span class="heat-map-marker__type-shape" :class="`heat-map-marker__type-shape--${typeIndex}`" />
                </span>
            </span>
            <transition name="heat-map-scale">
                <span v-show="marker.text !== ''" class="heat-map-marker__comment-dot" />
            </transition>

            <transition name="heat-map-scale">
                <span v-show="focused" class="heat-map-marker__options" :class="{ 'heat-map-marker__options--swapped': swapUI }">
                    <font-awesome-icon class="heat-map-marker__remove" :icon="['fas', 'times']" @click="remove" />
                </span>
            </transition>
            <transition name="heat-map-scale-to-right">
                <div v-show="focused" class="heat-map-marker__comment-container" :class="{ 'heat-map-marker__comment-container--swapped': swapUI }">
                    <div class="heat-map-marker__comment-wrapper">
                        <textarea
                            :id="`text-${marker.id}`"
                            ref="input"
                            v-model="text"
                            type="text"
                            class="heat-map-marker__input"
                            rows="3"
                            title="Marker comment"
                            value=""
                            placeholder="Comment (optional)"
                        />
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import debounce from 'debounce';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import {
        faMapMarker,
        faMapMarkerPlus,
        faMapMarkerMinus,
        faTimes,
    } from '@fortawesome/pro-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import ClickOutsidePlugin from '../../../../Core/Layout/ClickOutsidePlugin';

    Vue.use(ClickOutsidePlugin);

    library.add(faMapMarker);
    library.add(faMapMarkerPlus);
    library.add(faMapMarkerMinus);
    library.add(faTimes);

    export default {
        name: 'HeatMapMarker',
        components: {
            FontAwesomeIcon,
        },
        props: {
            marker: {
                type: Object,
                required: true,
            },
            multiplier: {
                type: Number,
                required: true,
            },
            types: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                focused: this.marker.focused || false,
                initialised: false,
            };
        },
        computed: {
            left() {
                return this.marker.x / this.multiplier;
            },
            top() {
                return this.marker.y / this.multiplier;
            },
            position() {
                return `left: ${this.left}px; top: ${this.top}px`;
            },
            text: {
                get() {
                    return this.marker.text;
                },
                set(value) {
                    this.value = value;
                    this.$emit('update', {
                        id: this.marker.id,
                        text: value,
                    });
                },
            },
            swapUI() {
                return ((window.innerWidth - this.left) < 220);
            },
            typeIndex() {
                return this.types.findIndex((type) => type.id === this.marker.type) + 1;
            },
        },
        created() {
            this.debounceSetFocus = debounce(this.setFocus, 500);
        },
        mounted() {
            setTimeout(() => {
                this.initialised = true;
            }, 1000);
        },
        methods: {
            changeType(type) {
                this.$emit('update', {
                    id: this.marker.id,
                    type,
                });
            },
            remove() {
                this.$emit('remove', this.marker.id);
            },
            setFocus(state) {
                this.focused = state;
            },
            testingMethod() {
                if (this.initialised && this.focused) {
                    this.debounceSetFocus(false);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    $type-colors: #2278cf, #cf2267, #cfc922;

    .heat-map-marker {
        cursor: auto;
        display: inline-flex;
        position: absolute;
        transition: all .2s cubic-bezier(.175, .885, .32, 1.275);
        z-index: 2;

        &--focused {
            z-index: 3;
        }

        &--1 {
            color: nth($type-colors, 1);
        }

        &--2 {
            color: nth($type-colors, 2);
        }

        &--3 {
            color: nth($type-colors, 3);
        }

        &:hover {
            /deep/ .heat-map-marker {
                &__icon-wrapper {
                    transform: scale(1.1) translateX(-50%) translateY(-50%);
                }
            }
        }

        &__icon-wrapper {
            animation: heatMapScale .2s cubic-bezier(.175, .885, .32, 1.275);
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            transform-origin: top left;

            &:hover {
                cursor: pointer;
            }
        }

        &__icon {
            color: inherit;
            font-size: 24px;
            transition: all .2s ease-in-out;

            &--background {
                left: 0;
                position: absolute;
                top: 0;
                z-index: -1;
            }
        }

        &__type-shape {
            display: inline-flex;
            position: absolute;
            right: 50%;
            transform: translateX(50%) translateY(-50%);
            z-index: 2;

            &--1 {
                background-color: #fff;
                height: 6px;
                top: calc(50% - 3px);
                width: 6px;
            }

            &--2 {
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 6px solid #fff;
                top: calc(50% - 4px);
            }

            &--3 {
                background-color: #fff;
                height: 6px;
                top: calc(50% - 3px);
                transform: translateX(50%) translateY(-50%) rotate(45deg);
                width: 6px;
            }
        }

        &__comment-dot {
            background-color: currentColor;
            border: 2px solid #fff;
            border-radius: 50%;
            bottom: 4px;
            height: 10px;
            position: absolute;
            right: -2px;
            width: 10px;
        }

        &__wrapper {
            height: 40px;
            position: relative;
            width: 28px;
        }

        &__options {
            align-items: center;
            border-radius: 50%;
            display: inline-flex;
            height: 40px;
            justify-content: center;
            left: -40px;
            position: absolute;
            top: 0;
            width: 40px;

            &--swapped {
                left: unset;
                right: -40px;
            }
        }

        &__remove {
            background: #fff;
            border-radius: 50%;
            box-shadow: 0 2px 15px 5px rgba(0, 0, 0, .1);
            color: $color-grey-dark;
            display: inline-flex;
            font-size: 14px;
            height: 22px;
            margin: 0;
            padding: $gutter-xs;
            transition: all .2s ease-in-out;
            width: 22px;

            &:hover {
                color: $color-red-flamingo;
                cursor: pointer;
                transform: scale(1.2);
            }
        }

        &__comment-container {
            display: inline-flex;
            left: 100%;
            padding: $gutter-s $gutter-s $gutter-s calc(#{$gutter-s} + #{$gutter-xs});
            position: absolute;
            top: -#{$gutter-s};
            width: 200px;

            &--swapped {
                left: unset;
                padding: $gutter-s calc(#{$gutter-s} + #{$gutter-xs}) $gutter-s $gutter-s;
                right: 100%;
            }
        }

        &__comment-wrapper {
            background: #fff;
            border: 0;
            border-radius: 4px;
            box-shadow: 0 2px 15px 5px rgba(0, 0, 0, .1);
            display: inline-flex;
            width: 100%;
        }

        &__input {
            background: transparent;
            border: 0;
            border-bottom: 2px solid $color-grey-light;
            border-radius: 4px;
            box-shadow: none;
            font-size: 14px;
            line-height: 1.3;
            margin: 0;
            min-height: 40px;
            outline: 0;
            padding: $gutter-s;
            position: relative;
            resize: vertical;
            width: 100%;

            &:focus,
            &:active {
                border: 0 !important;
                border-bottom: 2px solid var(--color-primary) !important;
            }
        }
    }

    @keyframes heatMapScale {
        0% {
            transform: scale(0) translateX(-50%) translateY(-50%);
        }

        100% {
            transform: scale(1) translateX(-50%) translateY(-50%);
        }
    }
</style>

<style lang="scss">
    .heat-map-marker {
        &__icon {
            &--background {
                color: #fff !important;

                path {
                    stroke: #fff;
                    stroke-width: 84px;
                }
            }
        }

        // Temporary styling to overwrite legacy CSS
        .heat-map-marker {
            &__input {
                padding: $gutter-s !important;
                min-width: 100%;
            }
        }
    }

    .heat-map-scale {
        &-enter-active {
            transition: all .2s cubic-bezier(.175, .885, .32, 1.275);
            transform-origin: center center;
        }

        &-leave-active {
            transition: all .2s ease-in-out;
            transform-origin: center center;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
            transform: scale(0);
        }
    }

    .heat-map-scale-to-right {
        &-enter-active,
        &-leave-active {
            transform-origin: left center;
        }

        &-enter-active {
            transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
        }

        &-leave-active {
            transition: all .3s ease-in-out;
        }

        &-enter,
        &-leave-to {
            opacity: 0;
            transform: scale(0) translateX(-12px);
        }
    }

    .heat-map-marker {
        transform: translateX(-14px) translateY(-32px); // Okay, no idea how to prevent this magic number... It aligns the 'point' of the 'pin' with the y coordinate.
    }

    [dir="rtl"] {
        .heat-map-marker {
            transform: translateX(14px) translateY(-32px); // Okay, no idea how to prevent this magic number... It aligns the 'point' of the 'pin' with the y coordinate.
        }
    }
</style>
