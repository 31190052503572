import 'whatwg-fetch';
import AuthenticationError from './AuthenticationError';
import AuthorizationError from './AuthorizationError';
import HttpError from './HttpError';
import NotFoundError from './NotFoundError';
import BadRequestError from './BadRequestError';
import HttpGoneError from './HttpGoneError';
import HttpConflictError from './HttpConflictError';

export default async function (input, options) {
    const request = new Request(input, {
        credentials: 'same-origin',
        ...options,
    });

    request.headers.set('X-Requested-With', 'XMLHttpRequest');

    if (request.method !== 'GET') {
        request.headers.set('Content-Type', 'application/json');

        const token = document.querySelector('meta[name="cmnty-csrf-token"]').content;
        if (token) {
            request.headers.set('X-CSRF-Token', token);
        }
    }

    return fetch(request)
        .then((response) => {
            if (response.ok) {
                return Promise.resolve({ response });
            }

            const message = `${request.method} ${request.url} - ${response.status} ${response.statusText}`;

            let error;
            switch (response.status) {
                case 400:
                    error = new BadRequestError(message);
                    break;
                case 401:
                    error = new AuthenticationError(message);
                    break;
                case 403:
                    error = new AuthorizationError(message);
                    break;
                case 404:
                    error = new NotFoundError(message);
                    break;
                case 409:
                    error = new HttpConflictError(message);
                    break;
                case 410:
                    error = new HttpGoneError(message);
                    break;
                default:
                    error = new HttpError(message);
                    break;
            }

            error.response = response;

            if (response.status === 400) {
                return response.json().then((body) => {
                    error.body = body;

                    return Promise.reject(error);
                });
            }

            return Promise.reject(error);
        });
}
