<template>
    <div class="heat-map-question-wrapper">
        <heat-map-question
            v-model="markers"
            :max-markers="maxMarkers"
            :image-source="imageSource"
            :initial-markers="parsedInitialMarkers"
            :types="types"
        />

        <div class="heat-map-question-wrapper__form-data">
            <div v-for="(marker, index) in markers" :key="marker.id">
                <input type="hidden" :name="`${name}[${index}][type]`" :value="marker.type">
                <input type="hidden" :name="`${name}[${index}][coordinate][x]`" :value="marker.x">
                <input type="hidden" :name="`${name}[${index}][coordinate][y]`" :value="marker.y">
                <input type="hidden" :name="`${name}[${index}][comment]`" :value="marker.text">
            </div>
        </div>
    </div>
</template>

<script>
    import HeatMapQuestion from './HeatMapQuestion.vue';

    export default {
        name: 'HeatMapQuestionWrapper',
        components: {
            HeatMapQuestion,
        },
        props: {
            name: {
                type: String,
                required: true,
            },
            initialMarkers: {
                type: String,
                required: false,
                default: '[]',
            },
            markerTypes: {
                type: String,
                required: false,
                default: '[]',
            },
            maxMarkers: {
                type: Number,
                required: true,
            },
            imageSource: {
                type: String,
                required: true,
            },
        },
        data() {
            const markers = JSON.parse(this.initialMarkers).map((marker) => ({
                type: marker.type,
                x: marker.coordinate.x,
                y: marker.coordinate.y,
                text: marker.comment,
            }));

            const types = JSON.parse(this.markerTypes);

            return {
                parsedInitialMarkers: markers,
                markers,
                types,
            };
        },
    };
</script>
