import Vue from 'vue';

const store = {
    namespaced: true,
    state() {
        let settings = null;
        const settingsMeta = document.querySelector('meta[name="cmnty-platform-settings"]');
        if (settingsMeta) {
            settings = JSON.parse(settingsMeta.content);
        }

        let platformSeo = null;
        const platformSeoMeta = document.querySelector('meta[name="cmnty-platform-seo"]');
        if (platformSeoMeta) {
            platformSeo = JSON.parse(platformSeoMeta.content);
        }

        let pageConfiguration = null;
        const pageConfigurationMeta = document.querySelector('meta[name="cmnty-page-configuration"]');
        if (pageConfigurationMeta) {
            pageConfiguration = JSON.parse(pageConfigurationMeta.content);
        }

        return {
            settings: {
                ...settings,
                locale: document.documentElement.lang,
                sidebarExpanded: false,
                screenWidth: window.innerWidth,
            },
            platformSeo,
            pageConfiguration,
            activePage: null,
        };
    },
    getters: {
        getSetting: (state) => (setting) => {
            if (!state.settings || !state.settings[setting]) {
                return null;
            }

            return state.settings[setting];
        },
        getPlatformSeo: (state) => (type) => {
            if (!type) {
                return {
                    title: state.platformSeo.title,
                    meta: [
                        { vmid: 'description', name: 'description', content: state.platformSeo.description },
                        { vmid: 'keywords', name: 'keywords', content: state.platformSeo.keywords },
                    ],
                };
            }

            return state.platformSeo[type];
        },
        getElements: (state) => (location) => {
            if (!state.activePage || !state.pageConfiguration[state.activePage] || !state.pageConfiguration[state.activePage][location]) {
                return [];
            }

            return state.pageConfiguration[state.activePage][location];
        },
        isMobileScreenWidth: (state, getters) => getters.getSetting('screenWidth') < 500,
        getDateTimeFormat: (state, getters) => {
            const dateFormat = getters.getSetting('dateFormat');
            const timeFormat = getters.getSetting('timeFormat');
            return `${dateFormat} ${timeFormat}`;
        },
    },
    mutations: {
        setSetting(state, { setting, value }) {
            Vue.set(state.settings, setting, value);
        },
        changePage(state, { page }) {
            Vue.set(state, 'activePage', page);
        },
        disableElement(state, { location, elementName }) {
            const elementIndex = state.pageConfiguration[state.activePage][location].findIndex((element) => element.component === elementName);
            Vue.set(state.pageConfiguration[state.activePage][location][elementIndex], 'enabled', false);
        },
        enableElement(state, { location, elementName }) {
            if (!location || !elementName) {
                return;
            }
            const elementIndex = state.pageConfiguration[state.activePage][location].findIndex((element) => element.component === elementName);
            Vue.set(state.pageConfiguration[state.activePage][location][elementIndex], 'enabled', true);
        },
    },
};

export default store;
