var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ranking-question-preview" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { key: "parent", staticClass: "list-item-parent" },
      [
        _c(
          "draggable",
          {
            on: {
              change: function($event) {
                _vm.listChanged = true
              }
            },
            model: {
              value: _vm.list,
              callback: function($$v) {
                _vm.list = $$v
              },
              expression: "list"
            }
          },
          [
            _c(
              "transition-group",
              _vm._l(_vm.list, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "list-item" },
                  [
                    _c(
                      "select",
                      {
                        staticClass: "ranking-select",
                        attrs: {
                          id: "rankingSelect",
                          name: "ranking",
                          placeholder: "select rank"
                        },
                        on: {
                          change: function($event) {
                            return _vm.optChanged(index, $event)
                          }
                        }
                      },
                      [
                        !_vm.listChanged
                          ? _c("option", [
                              _vm._v(
                                "\n                            -\n                        "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.list, function(itemOpt, optIndex) {
                          return _c(
                            "option",
                            {
                              key: optIndex,
                              domProps: {
                                selected: _vm.listChanged && index === optIndex,
                                value: optIndex
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(optIndex + 1) +
                                  "\n                        "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "list-item-name" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "grip-dots-vertical"] }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ranking-question-wrapper__form-data" },
          _vm._l(_vm.list, function(data, index) {
            return _c("div", { key: data.id }, [
              _c("input", {
                attrs: {
                  type: "hidden",
                  name:
                    _vm.name +
                    "[" +
                    data.questionnaire_question_id +
                    "][answerPositions][" +
                    index +
                    "][answer]"
                },
                domProps: { value: data.questionnaire_question_answer_id }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  type: "hidden",
                  name:
                    _vm.name +
                    "[" +
                    data.questionnaire_question_id +
                    "][answerPositions][" +
                    index +
                    "][position]"
                },
                domProps: { value: index + 1 }
              })
            ])
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rank-hint" }, [
      _c("span", [
        _vm._v(
          "\n            Drag and drop, or select your preferred rank for each item.\n        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }