<template>
    <div class="ranking-question-preview">
        <div class="rank-hint">
            <span>
                Drag and drop, or select your preferred rank for each item.
            </span>
        </div>
        <div key="parent" class="list-item-parent">
            <draggable v-model="list" @change="listChanged = true">
                <transition-group>
                    <div v-for="(item, index) in list" :key="index" class="list-item">
                        <select
                            id="rankingSelect"
                            class="ranking-select"
                            name="ranking"
                            placeholder="select rank"
                            @change="optChanged(index, $event)"
                        >
                            <option v-if="!listChanged">
                                -
                            </option>
                            <option
                                v-for="(itemOpt, optIndex) in list"
                                :key="optIndex"
                                :selected="listChanged && index === optIndex"
                                :value="optIndex"
                            >
                                {{ optIndex + 1 }}
                            </option>
                        </select>
                        <span class="list-item-name">{{ item.name }}</span>
                        <font-awesome-icon :icon="['fas', 'grip-dots-vertical']" />
                    </div>
                </transition-group>
            </draggable>
            <div class="ranking-question-wrapper__form-data">
                <div v-for="(data, index) in list" :key="data.id">
                    <input type="hidden" :name="`${name}[${data.questionnaire_question_id}][answerPositions][${index}][answer]`" :value="data.questionnaire_question_answer_id">
                    <input type="hidden" :name="`${name}[${data.questionnaire_question_id}][answerPositions][${index}][position]`" :value="index + 1">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import { library } from '@fortawesome/fontawesome-svg-core';
    import {
        faGripDotsVertical,
    } from '@fortawesome/pro-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    library.add(faGripDotsVertical);
    export default {
        name: 'RankingQuestionPreview',
        components: {
            draggable,
            FontAwesomeIcon,
        },
        props: {
            name: {
                type: String,
                required: true,
            },
            question: {
                type: String,
                default: () => {
                },
            },
        },
        data() {
            return {
                list: [],
                listChanged: false,
            };
        },
        created() {
            if (this.question && JSON.parse(this.question)) {
                this.list = JSON.parse(this.question).answer_options;
            }
        },
        methods: {
            optChanged(prevIndex, data) {
                const newIndex = data.target.value;
                // Create a copy of the original array
                const newArray = [...this.list];

                // Get the element to be moved
                const element = newArray.splice(prevIndex, 1)[0];

                // Insert the element at the future index
                newArray.splice(+newIndex, 0, element);
                // Selected property of SELECT tag is not reactive so forcing
                this.list = null;
                setTimeout(() => {
                    this.list = newArray;
                    this.listChanged = true;
                    this.$emit('change', this.list);
                }, 1);
            },
        },
    };
</script>
<style lang="scss" scoped>
.list-item-parent span {
    display: inline-flex;
    flex-direction: column;
}

.list-item {
    display: flex;
    margin-bottom: $gutter-xs;
    border: 1px solid;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: grab;
}

.list-item:hover {
    background: $color-grey-light;
}

.list-item-name {
    margin-right: 10px;
    flex: 1 1 0;
    max-width: 100%;
    min-width: 140px;
}

.ranking-select {
    width: 50px;
    margin-right: 20px;
}
.rank-hint {
    font-style: italic;
    color: $color-grey-darker;
    margin: 0px 0px 5px 0px;
}
</style>
