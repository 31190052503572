import Vue from 'vue';
import Vuex from 'vuex';

import UserStore from './Core/User/UserStore';
import PlatformStore from './Core/Platform/PlatformStore';
import ConfigurationStore from './Core/Configuration/ConfigurationStore';
import FormStore from './Core/Forms/FormStore';
import NpsStore from './Core/Nps/NpsStore';
import BookmarkCategoriesStore from './Core/Bookmark/BookmarkCategoriesStore';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user: UserStore,
        platform: PlatformStore,
        configuration: ConfigurationStore,
        forms: FormStore,
        nps: NpsStore,
        bookmarkCategories: BookmarkCategoriesStore,
    },
});

export default store;
