var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "widget blog-categories-widget",
      class: "blog-categories-widget--" + _vm.position
    },
    [
      _vm.description
        ? _c("div", { staticClass: "widget__description" }, [
            _vm._v("\n        " + _vm._s(_vm.description) + "\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "smooth-reflow",
        { staticClass: "widget__content blog-categories-widget__body" },
        [
          _c(
            "ul",
            {
              staticClass: "blog-categories-widget__categories",
              attrs: { id: "injectable" }
            },
            [
              _c(
                "li",
                {
                  staticClass:
                    "blog-categories-widget__category blog-categories-widget__category--all single-category-wrapper blog-categories-widget-injectable"
                },
                [
                  _c("div", { staticClass: "parent-category" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "blog-categories-widget__category-link pointer",
                        attrs: { href: _vm.articlepath }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "blog-categories-widget__category-name pl-19"
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.allarticlelabel) +
                                "\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "blog-categories-widget__category-article-count"
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.allArticlesCount) +
                                "\n                        "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.getCategories, function(category) {
                return _c("single-category", {
                  key: category.id,
                  staticClass: "blog-categories-widget-injectable",
                  attrs: { category: category, "use-anchor-tag": true }
                })
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }