export default class UrlGenerator {
    routeStorage;

    constructor(routeStorage) {
        this.routeStorage = routeStorage;
    }

    /** @return string */
    generate(name, parameters = {}) {
        const route = this.routeStorage.getRoute(name);
        if (!route) {
            throw new Error(`The route "${name}" does not exists. Did you forget to export it?`);
        }

        let { path } = route;
        const unusedParams = [];
        Object.entries(parameters).forEach(([key, value]) => {
            if (!value && value !== 0) {
                return;
            }

            let replacement = value;

            if (key.match(/slug/i)) {
                replacement = (value.match(/([\u0E00-\u0E7F\p{L}0-9-]+)/ug) || []).join('-');
            }

            const requirement = route.requirements[key];
            if (requirement) {
                const regex = requirement === '([\\p{Thai}\\p{L}0-9-]+)?' ? /([\u0E00-\u0E7F\p{L}0-9-]+)?/ug : new RegExp(requirement);
                if (!regex.test(replacement)) {
                    throw new Error(`The value "${replacement}" for parameter "${key}" does not match "${requirement}".`);
                }
            }

            if (path.match(`{${key}}`) !== null) {
                path = path.replace(`{${key}}`, replacement);
            } else {
                unusedParams.push({ key, value: replacement });
            }
        });

        const matches = path.match(/{\w+}/g);
        if (matches) {
            throw new Error(`The route ${name} is missing parameters ${matches.toString()} for the path ${path}.`);
        }

        if (unusedParams.length > 0) {
            const url = new URL(path, window.location.origin);
            for (let i = 0; i < unusedParams.length; i += 1) {
                url.searchParams.set(unusedParams[i].key, unusedParams[i].value);
            }
            path = `${url.pathname}${url.search}`;
        }

        return path;
    }

    /** @return string */
    generateAbsolute(name, parameters = {}) {
        return `${window.location.origin}${this.generate(name, parameters)}`;
    }
}
