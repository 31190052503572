import fetch from '../../Core/Http/fetch';

export default class TaskListApi {
    static async fetchTasks() {
        return fetch('/_task-list/tasks', { method: 'GET' })
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async isBlockedByTask(itemId, module, action) {
        const url = module ? `/_task-list/tasks/check/${action}/${itemId}/${module}` : `/_task-list/tasks/check/${action}`;

        return fetch(url)
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }
}
