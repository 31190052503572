var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.testingMethod,
          expression: "testingMethod"
        }
      ],
      staticClass: "heat-map-marker",
      class: [
        { "heat-map-marker--focused": _vm.focused },
        "heat-map-marker--" + _vm.typeIndex
      ],
      style: _vm.position,
      on: {
        mouseleave: function($event) {
          return _vm.debounceSetFocus(false)
        }
      }
    },
    [
      _c(
        "div",
        {
          ref: "marker",
          staticClass: "heat-map-marker__wrapper",
          on: {
            mouseenter: function($event) {
              return _vm.debounceSetFocus(true)
            }
          }
        },
        [
          _c("span", { staticClass: "heat-map-marker__icon-wrapper" }, [
            _c(
              "span",
              { staticClass: "heat-map-marker__icon-inner-wrapper" },
              [
                _c("font-awesome-icon", {
                  staticClass:
                    "heat-map-marker__icon heat-map-marker__icon--background",
                  attrs: { icon: ["fas", "map-marker"] }
                }),
                _vm._v(" "),
                _c("font-awesome-icon", {
                  staticClass: "heat-map-marker__icon heat-map-marker__icon",
                  attrs: { icon: ["fas", "map-marker"] }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "heat-map-marker__type-shape",
                  class: "heat-map-marker__type-shape--" + _vm.typeIndex
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "heat-map-scale" } }, [
            _c("span", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.marker.text !== "",
                  expression: "marker.text !== ''"
                }
              ],
              staticClass: "heat-map-marker__comment-dot"
            })
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "heat-map-scale" } }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.focused,
                    expression: "focused"
                  }
                ],
                staticClass: "heat-map-marker__options",
                class: { "heat-map-marker__options--swapped": _vm.swapUI }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "heat-map-marker__remove",
                  attrs: { icon: ["fas", "times"] },
                  on: { click: _vm.remove }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "heat-map-scale-to-right" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.focused,
                    expression: "focused"
                  }
                ],
                staticClass: "heat-map-marker__comment-container",
                class: {
                  "heat-map-marker__comment-container--swapped": _vm.swapUI
                }
              },
              [
                _c("div", { staticClass: "heat-map-marker__comment-wrapper" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.text,
                        expression: "text"
                      }
                    ],
                    ref: "input",
                    staticClass: "heat-map-marker__input",
                    attrs: {
                      id: "text-" + _vm.marker.id,
                      type: "text",
                      rows: "3",
                      title: "Marker comment",
                      value: "",
                      placeholder: "Comment (optional)"
                    },
                    domProps: { value: _vm.text },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.text = $event.target.value
                      }
                    }
                  })
                ])
              ]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }