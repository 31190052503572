export function RandomStringGenerator(length = 5) {
    let randomString = '';
    const availableCharacters = 'abcdefghijklmnopqrstuvwxyz';

    for (let i = 0; i < length; i += 1) {
        randomString += availableCharacters.charAt(Math.floor(Math.random() * availableCharacters.length));
    }

    return randomString;
}

export function humanizeFileSize(bytes) {
    let fileSizeInBytes = bytes;
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB'];
    do {
        fileSizeInBytes /= 1024;
        i += 1;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed() + byteUnits[i];
}

let debounceTimeout = null;
/** @deprecated Since this function only has one global timeout I think it is broken, use `import debounce from 'debounce';` instead */
export function debounce(callback, wait) {
    return (...args) => {
        const next = () => callback(...args);
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(next, wait);
    };
}

export function compareBooleans(condition1, condition2) {
    if (condition1 && condition2) {
        return 0;
    }
    if (condition1) {
        return -1;
    }
    if (condition2) {
        return 1;
    }

    return undefined;
}

export function addFiltersToUrl(url, filters) {
    if (!filters) {
        return url;
    }

    let newUrl = url;
    filters.forEach((filter) => {
        let multipleModifier = '';
        if (filter.multiple) {
            multipleModifier = '[]';
        }

        newUrl += newUrl.includes('?') ? '&' : '?';
        newUrl += `filter[${filter.key}]${multipleModifier}=${filter.value}`;
    });

    return newUrl;
}

export default {
    RandomStringGenerator,
    humanizeFileSize,
    debounce,
    compareBooleans,
    addFiltersToUrl,
};
