import Vue from 'vue';

const store = {
    namespaced: true,
    state() {
        return {
            dirtyStates: {},
        };
    },
    getters: {
        isDirty: (state) => Object.entries(state.dirtyStates).length > 0,
        isClean: (state) => Object.entries(state.dirtyStates).length === 0,
        isCleanWhileIgnoring: (state) => (id) => {
            const remainder = Object.entries(state.dirtyStates).filter(([key]) => {
                const regex = new RegExp(`${id}(:|$)`);

                return !regex.test(key);
            });

            return remainder.length === 0;
        },
        isFormDirty: (state) => (id) => !!state.dirtyStates[id],
        isFormClean: (state) => (id) => !state.dirtyStates[id],
    },
    mutations: {
        save(state, { id, dirty }) {
            Vue.set(state.dirtyStates, id, dirty);
        },
        remove(state, { id }) {
            Vue.delete(state.dirtyStates, id);
        },
    },
    actions: {
        markAsDirty({ commit, getters }, id) {
            if (getters.isFormDirty(id)) {
                return;
            }

            commit('save', {
                id,
                dirty: true,
            });
        },
        markAsClean({ commit, state }, id) {
            Object.keys(state.dirtyStates).forEach((key) => {
                const regex = new RegExp(`${id}(:|$)`);
                if (regex.test(key)) {
                    commit('remove', { id: key });
                }
            });
        },
    },
};

export default store;
