var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "task-item",
      class: {
        "task-item--completed": _vm.task.completed,
        "task-item--blocked": _vm.task.blocked
      }
    },
    [
      _c(
        "a",
        {
          staticClass: "task-item__link",
          attrs: { target: _vm.getTargetType(_vm.task), href: _vm.task.url }
        },
        [
          _c("div", { staticClass: "task-item__content" }, [
            _c("h3", { staticClass: "task-item__title" }, [
              _vm.task.status !== "active"
                ? _c(
                    "span",
                    {
                      staticClass: "task-item__status label",
                      class: "label--" + _vm.task.status
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("trans")("status.label." + _vm.task.status)
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(_vm._s(_vm.task.title) + "\n            ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "task-item__action" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.task.action) +
                  "\n            "
              )
            ])
          ]),
          _vm._v(" "),
          !_vm.task.blocked
            ? _c("span", [
                _vm.task.completed
                  ? _c("i", {
                      staticClass:
                        "task-item__icon task-item__icon--completed fa fas fa-check-circle",
                      attrs: {
                        title: _vm.$trans(
                          "task.completed",
                          {},
                          "task_list_frontend"
                        )
                      }
                    })
                  : _c("i", {
                      staticClass: "task-item__icon fa fas fa-arrow-right"
                    })
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.task.blocked
        ? _c("div", { staticClass: "task-item__blocked-overlay" }, [
            _c("div", { staticClass: "task-item__content" }, [
              _c("h3", { staticClass: "task-item__title" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.task.title) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "task-item__action" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm._f("$trans")(
                        "task.available",
                        {
                          "%taskTitle%": '"' + _vm.getBlockingTaskTitle() + '"'
                        },
                        "task_list_frontend"
                      )
                    ) +
                    "\n            "
                )
              ])
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "task-item__icon fa fas fa-lock" })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }