export default class Translator {
    /** @var {TranslationStorage} */
    translationStorage;

    constructor(translationStorage) {
        this.translationStorage = translationStorage;
    }

    /**
     * @param {string} domain
     *
     * @return {Promise<object>}
     */
    async load(domain) {
        return this.translationStorage.load(domain);
    }

    /**
     * @param {string} key
     * @param {object} parameters
     * @param {string} domain
     *
     * @return {string}
     */
    trans(key, parameters = {}, domain = 'frontend', shouldEscapeHTML = false) {
        if (!this.translationStorage.hasTranslation(key, domain)) {
            return '';
        }

        const template = this.translationStorage.getTranslation(key, domain);

        return Translator.parseParameters(template, parameters, shouldEscapeHTML);
    }

    transChoice(key, amount, parameters = {}, domain = 'frontend') {
        if (!this.translationStorage.hasTranslation(key, domain)) {
            return '';
        }

        const template = this.translationStorage.getTranslation(key, domain);
        const message = this.translationStorage.selectMessage(template, amount);
        if (!message) {
            return '';
        }

        return Translator.parseParameters(message, { '%count%': amount, '{amount}': amount, ...parameters });
    }

    static parseParameters(template, parameters, shouldEscapeHTML = true) {
        let message = shouldEscapeHTML ? Translator.escapeHTML(template) : template;
        Object.entries(parameters).forEach(([placeholder, replacement]) => {
            const search = new RegExp(placeholder, 'g');

            message = message.replace(search, replacement);
        });

        return message;
    }

    static escapeHTML(html) {
        const textNode = document.createTextNode(html);

        const htmlNode = document.createElement('p');
        htmlNode.appendChild(textNode);

        return htmlNode.innerHTML;
    }
}
