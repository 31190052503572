<template>
    <section class="widget task-list-widget">
        <h2 v-if="title" class="widget__title">
            {{ title }}
        </h2>
        <div v-if="description" class="widget__description">
            {{ description }}
        </div>

        <div ref="list" class="widget__content task-list-widget__content">
            <div v-for="(category, categoryIndex) in categories" :key="category.id" class="task-list-widget__category">
                <header
                    :class="`task-list-widget__category-header--${size} task-list-widget__category-header--${position}`"
                    class="task-list-widget__category-header"
                >
                    {{ category.name }}
                    <span>
                        <i :class="`task-item__icon fa fas ${getCategoryIcon(category)}`" />
                    </span>
                </header>
                <ol v-if="category.tasks.length > 0 || loading" class="task-list-widget__tasks" :class="`task-list-widget__tasks--${size} task-list-widget__tasks--${position}`">
                    <task-item
                        v-for="task in category.tasks"
                        :key="task.id"
                        :task="task"
                        :blocking-task="getBlockingTaskInCategory(category, task)"
                        :category-status="getCategoryStatus(category, categoryIndex)"
                        class="task-list-widget__task"
                        :class="{ 'task-item--small': size === 'small', 'task-item--sidebar': position === 'sidebar' }"
                    />
                    <li v-if="loading" :key="loading" class="task-list-widget__loading">
                        <loading-spinner class="task-list-widget__loading-spinner" />
                    </li>
                </ol>
            </div>
            <div v-if="categories.length === 0 && !loading" class="task-list-widget__no-tasks no-results-message">
                <div class="no-results-message__content">
                    {{ 'task.no_results'|trans({}, 'task_list_frontend') }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import store from '../../Store';
    import TaskListStore from './TaskListStore';
    import TaskItem from './TaskItem.vue';

    store.registerModule('taskList', TaskListStore);

    export default {
        name: 'TaskListWidget',
        components: {
            TaskItem,
        },
        props: {
            title: {
                type: String,
                required: false,
                default: 'Task List',
            },
            description: {
                type: String,
                required: false,
                default: '',
            },
            position: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                size: 'large',
            };
        },
        computed: {
            categories() {
                return this.$store.getters['taskList/getSortedCategories'];
            },
            loading() {
                return this.$store.getters['taskList/loading'];
            },
        },
        watch: {
            $route() {
                setTimeout(() => {
                    this.determineWidgetSize();
                }, 500);
            },
        },
        created() {
            this.$translator.load('task_list_frontend');
            this.$store.commit('taskList/registerWidget');
        },
        async mounted() {
            window.addEventListener('resize', this.determineWidgetSize);
            await this.$store.dispatch('taskList/fetchTasks');
            await this.$nextTick();
            this.determineWidgetSize();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.determineWidgetSize);
            this.$store.commit('taskList/unregisterWidget');
        },
        methods: {
            determineWidgetSize() {
                if (!this.$refs.list) {
                    return;
                }

                let size = 'large';
                const listWidth = this.$refs.list.offsetWidth;

                if (listWidth < 700) {
                    size = 'small';
                } else if (listWidth < 1050) {
                    size = 'medium';
                }

                this.size = size;
            },
            getBlockingTaskInCategory(category, task) {
                if (task.blocked) {
                    const blockedTaskIndex = category.tasks.indexOf(task);
                    return category.tasks[blockedTaskIndex - 1];
                }
                return null;
            },
            getCategoryStatus(category, categoryIndex) {
                if (category.tasks.length > 0 && category.tasks[0].blocked) {
                    return { status: 'blocked', categoryName: this.categories[categoryIndex - 1]?.name };
                }

                const categoryObjectStatus = { status: 'completed', categoryName: category.name };
                category.tasks.forEach((task) => {
                    if (!task.completed) {
                        categoryObjectStatus.status = 'incomplete';
                    }
                });

                return categoryObjectStatus;
            },
            getCategoryIcon(category) {
                const status = this.getCategoryStatus(category);

                switch (status.status) {
                    case 'completed':
                        return 'fa-check';
                    case 'blocked':
                        return 'fa-lock';
                    default:
                        return 'fa-arrow-right';
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .task-list-widget {
        &__category {
            display: flex;
            flex-direction: column;

            &:first-child {
                margin-top: $gutter-m;
            }
        }

        &__category-header {
            display: flex;
            justify-content: space-between;
            background: var(--color-primary-tint-65);
            color: black;
            font-size: 18px;
            margin-bottom: 0;
            margin-left: -#{$gutter-l};
            margin-right: -#{$gutter-l};
            padding: $gutter-m $gutter-l;
            text-transform: uppercase;
            width: calc(100% + 2 * #{$gutter-l});

            &--small {
                border-bottom: 1px solid $color-grey-light;
            }

            &--sidebar {
                margin-left: -16px;
                margin-right: -16px;
                padding: $gutter-m;
                width: calc(100% + 2 * #{$gutter-m});
            }
        }

        &__tasks {
            @include reset-list();
            display: flex;
            flex-wrap: wrap;
            margin-left: -#{$gutter-l};
            margin-right: -#{$gutter-l};
            min-height: 100px;
            position: relative;
            width: calc(100% + 2 * #{$gutter-l});

            &--small {
                /deep/ .task-list-widget {
                    &__task {
                        width: 100%;

                        &:first-child {
                            border-top-width: 0;
                        }
                    }
                }
            }

            &--medium {
                /deep/ .task-list-widget {
                    &__task {
                        margin-right: -1px;
                        width: calc(50% + 1px);

                        &:not(:nth-child(2n)) {
                            border-right-width: 1px;
                        }
                    }
                }
            }

            &--large {
                /deep/ .task-list-widget {
                    &__task {
                        margin-right: -1px;
                        width: calc(100% / 3 + 1px);

                        &:not(:nth-child(3n)) {
                            border-right-width: 1px;
                        }
                    }
                }
            }

            &--sidebar {
                margin-left: -#{$gutter-m};
                margin-right: -#{$gutter-m};
                width: calc(100% + 2 * #{$gutter-m});
            }
        }

        &__task {
            border-color: $color-grey-light;
            border-style: solid;
            border-width: 0;
            border-bottom-width: 1px;
            border-top-width: 1px;
            margin-bottom: -1px;
            min-width: 250px;
        }

        &__loading {
            align-items: center;
            background: rgba(#fff, .8);
            display: inline-flex;
            height: calc(100% + 1px); // 1px 'Corrects' the negative margin-bottom of the tasks
            justify-content: center;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        /deep/ .task-list-widget {
            &__loading-spinner {
                height: 40px;
                width: 40px;
            }
        }
    }
</style>
