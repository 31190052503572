import Vue from 'vue';
import NpsApi from './NpsApi';

const store = {
    namespaced: true,
    state() {
        return {
            visible: {},
        };
    },
    getters: {
        isVisible: (state) => (scope) => state.visible[scope] || false,
    },
    mutations: {
        save(state, { scope, visible }) {
            Vue.set(state.visible, scope, visible);
        },
    },
    actions: {
        async maybeShowQuestion({ dispatch }, { scope }) {
            const { body } = await NpsApi.ask(scope);
            if (body[scope]) {
                dispatch('showQuestion', { scope });
            }
        },
        showQuestion({ commit, getters }, { scope }) {
            if (getters.isVisible(scope)) {
                return;
            }

            commit('save', { scope, visible: true });
        },
        hideQuestion({ commit, getters }, { scope }) {
            if (!getters.isVisible(scope)) {
                return;
            }

            commit('save', { scope, visible: false });
        },
    },
};

export default store;
