import RouteStorage from './RouteStorage';
import UrlGenerator from './UrlGenerator';
import routes from '../../../../var/routing/routes.json';

export default {
    install(Vue) {
        const routeStorage = new RouteStorage(routes);
        const urlGenerator = new UrlGenerator(routeStorage);

        // Global methods / properties.
        // Use as Vue.urlGenerator
        // eslint-disable-next-line no-param-reassign
        Vue.urlGenerator = urlGenerator;

        // Instance methods / properties
        // Use withing vue components as this.$urlGenerator
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$urlGenerator = urlGenerator;
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$path = (name, parameters = {}) => urlGenerator.generate(name, parameters);
        // eslint-disable-next-line no-param-reassign
        Vue.$path = (name, parameters = {}) => urlGenerator.generate(name, parameters);
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$url = (name, parameters = {}) => urlGenerator.generateAbsolute(name, parameters);
    },
};
