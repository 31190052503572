var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "heat-map-question" }, [
    _c("header", { staticClass: "heat-map-question__header" }, [
      _c(
        "div",
        {
          staticClass: "heat-map-question__type-selection",
          class: {
            "heat-map-question__type-selection--disabled":
              _vm.maxMarkers !== 0 && _vm.markers.length >= _vm.maxMarkers
          }
        },
        _vm._l(_vm.types, function(type, index) {
          return _c(
            "div",
            {
              key: type.id,
              staticClass: "heat-map-question__type",
              class: [
                "heat-map-question__type--" + type.id,
                _vm.selectedType !== type.id
                  ? "heat-map-question__type--inactive"
                  : "heat-map-question__type--active"
              ]
            },
            [
              _c(
                "label",
                {
                  staticClass: "heat-map-question__type-button",
                  attrs: {
                    for: "heat-map-type-" + _vm.randomId + "-" + type.id
                  }
                },
                [
                  _c("span", { staticClass: "heat-map-question__type-label" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(type.label) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    staticClass:
                      "heat-map-question__type-icon heat-map-question__type-icon--background",
                    attrs: { icon: ["fas", "map-marker"] }
                  }),
                  _vm._v(" "),
                  _c("font-awesome-icon", {
                    staticClass: "heat-map-question__type-icon",
                    attrs: { icon: ["fas", "map-marker"] }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "heat-map-question__type-shape",
                    class: "heat-map-question__type-shape--" + (index + 1)
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedType,
                    expression: "selectedType"
                  }
                ],
                staticClass: "heat-map-question__type-input",
                attrs: {
                  id: "heat-map-type-" + _vm.randomId + "-" + type.id,
                  type: "radio"
                },
                domProps: {
                  value: type.id,
                  checked: _vm._q(_vm.selectedType, type.id)
                },
                on: {
                  change: function($event) {
                    _vm.selectedType = type.id
                  }
                }
              })
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.maxMarkers > 0
        ? _c("div", { staticClass: "heat-map-question__max-markers-label" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm._f("trans")("heat_map.markers_left", {
                    "%markersLeft%": _vm.leftMarkers
                  })
                ) +
                "\n        "
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "heat-map-question__wrapper" }, [
      _c("img", {
        ref: "image",
        staticClass: "heat-map-question__image",
        attrs: { src: _vm.imageSource, alt: "HeatMap marker image" }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "container",
          staticClass:
            "heat-map-question__container heat-map-question__container",
          on: {
            click: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.addMarker($event)
            }
          }
        },
        _vm._l(_vm.markers, function(marker) {
          return _c("heat-map-marker", {
            key: marker.id,
            attrs: {
              marker: marker,
              multiplier: _vm.multiplier,
              types: _vm.types
            },
            on: { update: _vm.updateMarker, remove: _vm.removeMarker }
          })
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }