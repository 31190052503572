import { render, staticRenderFns } from "./SingleCategory.vue?vue&type=template&id=90754144&"
import script from "./SingleCategory.vue?vue&type=script&lang=js&"
export * from "./SingleCategory.vue?vue&type=script&lang=js&"
import style0 from "./SingleCategory.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/4/platform/front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('90754144')) {
      api.createRecord('90754144', component.options)
    } else {
      api.reload('90754144', component.options)
    }
    module.hot.accept("./SingleCategory.vue?vue&type=template&id=90754144&", function () {
      api.rerender('90754144', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Module/Blog/Widget/SingleCategory.vue"
export default component.exports