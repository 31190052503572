import Vue from 'vue';
import fetch from '../Http/fetch';

export default {
    namespaced: true,
    state() {
        return {
            categories: [],
        };
    },
    getters: {
        allCategories: (state) => state.categories,
    },
    mutations: {
        saveCategories(state, categories) {
            Vue.set(state, 'categories', categories);
        },
    },
    actions: {
        async fetchCategories({ commit }) {
            const url = '/pulse/bookmarks/categories';
            const { body: { data: categories } } = await fetch(url).then(({ response }) => response.json().then((body) => ({ response, body })));

            const mappedCategories = Array.from(categories, (x) => x.name);
            commit('saveCategories', mappedCategories);
        },
    },
};
