import {
    MANAGERS_WITH_OBSERVERS_AND_CMNTY,
    MANAGERS_WITH_CMNTY,
    OBSERVER,
    PARTICIPANT,
} from './Roles';

export default {
    namespaced: true,
    state() {
        let user = null;
        const userMeta = document.querySelector('meta[name="cmnty-current-user"]');
        if (userMeta) {
            user = JSON.parse(userMeta.content);
        }

        let csrfToken = null;
        const csrfTokenMeta = document.querySelector('meta[name="cmnty-csrf-token"]');
        if (csrfTokenMeta) {
            csrfToken = csrfTokenMeta.content;
        }

        return {
            user,
            csrfToken,
        };
    },
    getters: {
        getUser: (state) => state.user,
        getUrn: (state) => (state.user ? state.user.urn : null),
        getCSRFToken: (state) => state.csrfToken,
        isLoggedIn: (state) => state.user && state.user.is_logged_in,
        isImpersonating: (state) => state.user && state.user.impersonating,
        isModeratorOrObserver: (state) => MANAGERS_WITH_OBSERVERS_AND_CMNTY.includes(state.user.role.type),
        isModerator: (state) => MANAGERS_WITH_CMNTY.includes(state.user.role.type),
        isObserver: (state) => state.user.role.type === OBSERVER,
        isParticipant: (state) => state.user.role.type === PARTICIPANT,
        isInvitedUser: (state) => state.user.is_invited_user,
    },
};
