var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "widget task-list-widget" }, [
    _vm.title
      ? _c("h2", { staticClass: "widget__title" }, [
          _vm._v("\n        " + _vm._s(_vm.title) + "\n    ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.description
      ? _c("div", { staticClass: "widget__description" }, [
          _vm._v("\n        " + _vm._s(_vm.description) + "\n    ")
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { ref: "list", staticClass: "widget__content task-list-widget__content" },
      [
        _vm._l(_vm.categories, function(category, categoryIndex) {
          return _c(
            "div",
            { key: category.id, staticClass: "task-list-widget__category" },
            [
              _c(
                "header",
                {
                  staticClass: "task-list-widget__category-header",
                  class:
                    "task-list-widget__category-header--" +
                    _vm.size +
                    " task-list-widget__category-header--" +
                    _vm.position
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(category.name) +
                      "\n                "
                  ),
                  _c("span", [
                    _c("i", {
                      class:
                        "task-item__icon fa fas " +
                        _vm.getCategoryIcon(category)
                    })
                  ])
                ]
              ),
              _vm._v(" "),
              category.tasks.length > 0 || _vm.loading
                ? _c(
                    "ol",
                    {
                      staticClass: "task-list-widget__tasks",
                      class:
                        "task-list-widget__tasks--" +
                        _vm.size +
                        " task-list-widget__tasks--" +
                        _vm.position
                    },
                    [
                      _vm._l(category.tasks, function(task) {
                        return _c("task-item", {
                          key: task.id,
                          staticClass: "task-list-widget__task",
                          class: {
                            "task-item--small": _vm.size === "small",
                            "task-item--sidebar": _vm.position === "sidebar"
                          },
                          attrs: {
                            task: task,
                            "blocking-task": _vm.getBlockingTaskInCategory(
                              category,
                              task
                            ),
                            "category-status": _vm.getCategoryStatus(
                              category,
                              categoryIndex
                            )
                          }
                        })
                      }),
                      _vm._v(" "),
                      _vm.loading
                        ? _c(
                            "li",
                            {
                              key: _vm.loading,
                              staticClass: "task-list-widget__loading"
                            },
                            [
                              _c("loading-spinner", {
                                staticClass: "task-list-widget__loading-spinner"
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ]
          )
        }),
        _vm._v(" "),
        _vm.categories.length === 0 && !_vm.loading
          ? _c(
              "div",
              { staticClass: "task-list-widget__no-tasks no-results-message" },
              [
                _c("div", { staticClass: "no-results-message__content" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("trans")(
                          "task.no_results",
                          {},
                          "task_list_frontend"
                        )
                      ) +
                      "\n            "
                  )
                ])
              ]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }