import Vue from 'vue';
import TaskListApi from './TaskListApi';

const taskListStore = {
    namespaced: true,
    state() {
        return {
            categories: {},
            loading: false,
            registeredWidgets: 0,
        };
    },
    getters: {
        getSortedCategories: (state) => {
            const { categories } = state;
            return Object.values(categories).sort((categoryA, categoryB) => categoryA.position - categoryB.position);
        },
        loading: (state) => state.loading,
    },
    mutations: {
        saveCategories(state, categories) {
            Vue.set(state, 'categories', categories);
        },
        setLoading(state, bool) {
            Vue.set(state, 'loading', bool);
        },
        registerWidget(state) {
            Vue.set(state, 'registeredWidgets', state.registeredWidgets + 1);
        },
        unregisterWidget(state) {
            Vue.set(state, 'registeredWidgets', state.registeredWidgets - 1);
        },
    },
    actions: {
        async fetchTasks({ state, commit }) {
            if (state.loading || state.registeredWidgets <= 0) {
                return;
            }
            commit('setLoading', true);

            try {
                const { body: { tasks, categories } } = await TaskListApi.fetchTasks();

                const categoriesWithTasks = {};
                Object.values(categories).forEach((category) => {
                    const categoryWithTasks = category;
                    categoryWithTasks.tasks = Object.values(tasks).filter((task) => task.category_id === category.id);
                    categoryWithTasks.tasks.sort((taskA, taskB) => taskA.position - taskB.position);
                    categoriesWithTasks[category.id] = categoryWithTasks;
                });
                commit('saveCategories', categoriesWithTasks);
                commit('setLoading', false);
            } catch (error) {
                commit('setLoading', false);
                throw error;
            }
        },
    },
};

export default taskListStore;
