<template>
    <div class="loading-spinner">
        <span class="loading-spinner__spinner" :class="color ? `loading-spinner__spinner--${color}` : ''" />
    </div>
</template>

<script>
    export default {
        name: 'LoadingSpinner',
        props: {
            color: {
                type: String,
                required: false,
                default: '',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .loading-spinner {
        height: 24px;
        width: 24px;

        &__spinner {
            animation: loadingSpin 1.1s infinite linear;
            border-bottom: 3px solid rgba(0, 0, 0, .2);
            border-left: 3px solid $color-grey-dark;
            border-radius: 50%;
            border-right: 3px solid rgba(0, 0, 0, .2);
            border-top: 3px solid rgba(0, 0, 0, .2);
            display: inline-block;
            height: 100%;
            width: 100%;

            &--primary {
                border-left-color: var(--color-primary);
            }
        }

        &[xlarge] {
            height: 60px;
            width: 60px;
        }
    }

    @keyframes loadingSpin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
