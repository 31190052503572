import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import Vue from 'vue';
import VueCustomElement from 'vue-custom-element';

import store from './Store';
import LocalizationPlugin from './Core/Localization/LocalizationPlugin';
import UrlGeneratorPlugin from './Core/Router/UrlGeneratorPlugin';

import LoadingSpinner from './Core/Progress/LoadingSpinner.vue';

import TaskListWidget from './Widget/TaskList/TaskListWidget.vue';
import HeatMapQuestionWrapper from './Module/Questionnaire/HeatMap/Question/HeatMapQuestionWrapper.vue';
import RankingQuestionPreview from './Module/Questionnaire/Ranking/RankingQuestionPreview.vue';
import BlogCategoriesWidgetInjectable from './Module/Blog/Widget/BlogCategoriesWidgetInjectable.vue';

Vue.use(VueCustomElement);

Vue.use(LocalizationPlugin, { store, locale: document.documentElement.lang });
Vue.use(UrlGeneratorPlugin, { store });

Vue.component(LoadingSpinner.name, LoadingSpinner);

TaskListWidget.store = store;
Vue.customElement('task-list-widget', TaskListWidget);
Vue.customElement('heat-map-question-wrapper', HeatMapQuestionWrapper);
Vue.customElement('ranking-question-preview', RankingQuestionPreview);
Vue.customElement('widget-blog-categories-injectable', BlogCategoriesWidgetInjectable);
