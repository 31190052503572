var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass:
        "blog-categories-widget__category blog-categories-widget__category--all single-category-wrapper"
    },
    [
      _c(
        "div",
        { staticClass: "parent-category" },
        [
          _vm.showSubCategoryToggle
            ? _c(
                "span",
                {
                  staticClass: "sub-category-toggle",
                  attrs: { tabindex: "0" },
                  on: {
                    click: function($event) {
                      _vm.showSubCategory = !_vm.showSubCategory
                    },
                    keypress: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.showSubCategory = !_vm.showSubCategory
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa-sharp fa-regular fa-angle-down",
                    class: { rotate: _vm.showSubCategory }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            _vm.linkComponent,
            {
              tag: "component",
              staticClass: "blog-categories-widget__category-link pointer",
              attrs: {
                href: _vm.useAnchorTag
                  ? "/blog/archive/" + _vm.category.id + "/" + _vm.category.name
                  : "#",
                to: _vm.useAnchorTag
                  ? null
                  : {
                      path: _vm.$path("cmnty_blog_overview_categories", {
                        id: _vm.category.id,
                        slug: _vm.category.name
                      })
                    }
              }
            },
            [
              _c(
                "span",
                {
                  class:
                    "blog-categories-widget__category-name " +
                    (_vm.showSubCategoryToggle ? "" : " pl-19")
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.category.name) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "blog-categories-widget__category-article-count"
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.category.article_count || _vm.category.articleCount
                      ) +
                      "\n            "
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSubCategory,
              expression: "showSubCategory"
            }
          ],
          staticClass: "sub-category-wrapper"
        },
        [
          _c(
            "ul",
            _vm._l(_vm.category.children, function(children) {
              return _c(
                "li",
                {
                  key: children.id,
                  staticClass: "sub-category-wrapper__list",
                  attrs: { tabindex: "0" }
                },
                [
                  _c(
                    _vm.linkComponent,
                    {
                      tag: "component",
                      staticClass:
                        "blog-categories-widget__category-link pointer",
                      attrs: {
                        href: _vm.useAnchorTag
                          ? "/blog/archive/" + children.id + "/" + children.name
                          : null,
                        to: _vm.useAnchorTag
                          ? null
                          : {
                              path: _vm.$path(
                                "cmnty_blog_overview_categories",
                                {
                                  id: children.id,
                                  slug: children.name
                                }
                              )
                            }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "sub-category-wrapper__list--name" },
                        [_vm._v(_vm._s(children.name))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "sub-category-wrapper__list--count" },
                        [
                          _vm._v(
                            _vm._s(
                              children.article_count || children.articleCount
                            )
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }