import Vue from 'vue';
import fetch from '../Http/fetch';

const promises = {};

const store = {
    namespaced: true,
    state: {
        locale: null,
        loadingDomains: [],
        loadedDomains: [],
        translations: {},
    },
    getters: {
        getLocale: (state) => state.locale,
        isDomainLoading: (state) => (domain) => state.loadingDomains.includes(domain),
        isDomainLoaded: (state) => (domain) => state.loadedDomains.includes(domain),
        getTranslations: (state) => (domain) => state.translations[domain],
        hasTranslationTemplate: (state) => (key, domain) => !!state.translations[domain] && !!state.translations[domain][key],
        getTranslationTemplate: (state) => (key, domain) => state.translations[domain][key],
    },
    mutations: {
        setLocale(state, { locale }) {
            Vue.set(state, 'locale', locale);
        },
        initializeDomain(state, { domain }) {
            state.loadingDomains.push(domain);
            if (!state.translations[domain]) {
                Vue.set(state.translations, domain, {});
            }
        },
        loadTranslationsIntoDomain(state, { domain, translations }) {
            state.loadedDomains.push(domain);
            state.loadingDomains.splice(state.loadingDomains.findIndex((element) => element === domain), 1);
            Vue.set(state.translations, domain, translations);
        },
    },
    actions: {
        async loadTranslations({ commit, getters }, { domain }) {
            if (getters.isDomainLoaded(domain)) {
                return Promise.resolve(getters.getTranslations(domain));
            }

            if (getters.isDomainLoading(domain)) {
                return promises[domain];
            }

            commit('initializeDomain', { domain });
            const promise = fetch(`/i18n/${getters.getLocale}/${domain}.json?${Date.now()}`)
                .then(({ response }) => response.json())
                .then((translations) => {
                    commit('loadTranslationsIntoDomain', { domain, translations });

                    return translations;
                });

            promises[domain] = promise;

            return promise;
        },
    },
};

export default store;
