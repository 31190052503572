import Vue from 'vue';
import fetch from '../Http/fetch';

export default class NpsApi {
    static async ask(scope) {
        return fetch(Vue.urlGenerator.generate('cmnty_nps_ask', { scope }))
            .then(({ response }) => response.json().then((body) => ({ response, body })));
    }

    static async submit(payload) {
        return fetch(Vue.urlGenerator.generate('cmnty_nps_submit'), { method: 'PUT', body: JSON.stringify(payload) });
    }
}
