export default class RouteStorage {
    routes;

    constructor(routes) {
        this.routes = routes;
    }

    getRoute(name) {
        return this.routes[name] || null;
    }
}
