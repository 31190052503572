var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "heat-map-question-wrapper" },
    [
      _c("heat-map-question", {
        attrs: {
          "max-markers": _vm.maxMarkers,
          "image-source": _vm.imageSource,
          "initial-markers": _vm.parsedInitialMarkers,
          types: _vm.types
        },
        model: {
          value: _vm.markers,
          callback: function($$v) {
            _vm.markers = $$v
          },
          expression: "markers"
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "heat-map-question-wrapper__form-data" },
        _vm._l(_vm.markers, function(marker, index) {
          return _c("div", { key: marker.id }, [
            _c("input", {
              attrs: {
                type: "hidden",
                name: _vm.name + "[" + index + "][type]"
              },
              domProps: { value: marker.type }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                name: _vm.name + "[" + index + "][coordinate][x]"
              },
              domProps: { value: marker.x }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                name: _vm.name + "[" + index + "][coordinate][y]"
              },
              domProps: { value: marker.y }
            }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "hidden",
                name: _vm.name + "[" + index + "][comment]"
              },
              domProps: { value: marker.text }
            })
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }