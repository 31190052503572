<template>
    <div class="heat-map-question">
        <header class="heat-map-question__header">
            <div class="heat-map-question__type-selection" :class="{ 'heat-map-question__type-selection--disabled': (maxMarkers !== 0 && markers.length >= maxMarkers) }">
                <div v-for="(type, index) in types" :key="type.id" class="heat-map-question__type" :class="[`heat-map-question__type--${type.id}`, selectedType !== type.id ? 'heat-map-question__type--inactive' : 'heat-map-question__type--active']">
                    <label class="heat-map-question__type-button" :for="`heat-map-type-${randomId}-${type.id}`">
                        <span class="heat-map-question__type-label">
                            {{ type.label }}
                        </span>
                        <font-awesome-icon class="heat-map-question__type-icon heat-map-question__type-icon--background" :icon="['fas', 'map-marker']" />
                        <font-awesome-icon class="heat-map-question__type-icon" :icon="['fas', 'map-marker']" />
                        <span class="heat-map-question__type-shape" :class="`heat-map-question__type-shape--${index + 1}`" />
                    </label>
                    <input
                        :id="`heat-map-type-${randomId}-${type.id}`"
                        v-model="selectedType"
                        type="radio"
                        :value="type.id"
                        class="heat-map-question__type-input"
                    >
                </div>
            </div>
            <div v-if="maxMarkers > 0" class="heat-map-question__max-markers-label">
                {{ 'heat_map.markers_left'|trans({ '%markersLeft%': leftMarkers }) }}
            </div>
        </header>
        <div class="heat-map-question__wrapper">
            <img ref="image" class="heat-map-question__image" :src="imageSource" alt="HeatMap marker image">
            <div ref="container" class="heat-map-question__container heat-map-question__container" @click.self="addMarker">
                <heat-map-marker
                    v-for="marker in markers"
                    :key="marker.id"
                    :marker="marker"
                    :multiplier="multiplier"
                    :types="types"
                    @update="updateMarker"
                    @remove="removeMarker"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faMapMarker, faMapMarkerPlus, faMapMarkerMinus } from '@fortawesome/pro-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

    import { RandomStringGenerator } from '../../../../Helpers';
    import HeatMapMarker from './HeatMapMarker.vue';

    library.add(faMapMarker);
    library.add(faMapMarkerPlus);
    library.add(faMapMarkerMinus);

    export default {
        name: 'HeatMapQuestion',
        components: {
            HeatMapMarker,
            FontAwesomeIcon,
        },
        props: {
            maxMarkers: {
                type: Number,
                required: true,
            },
            imageSource: {
                type: String,
                required: true,
            },
            initialMarkers: {
                type: Array,
                required: false,
                default() {
                    return [];
                },
            },
            types: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                selectedType: this.types[0].id,
                markers: this.initialMarkers.map((marker) => ({ id: RandomStringGenerator(10), ...marker })),
                multiplier: 1,
                randomId: '',
            };
        },
        computed: {
            leftMarkers() {
                return this.maxMarkers - this.markers.length;
            },
        },
        created() {
            this.randomId = RandomStringGenerator(10);
        },
        async mounted() {
            await this.defineImageMultiplier();
            window.addEventListener('resize', this.defineImageMultiplier);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.defineImageMultiplier);
        },
        methods: {
            async defineImageMultiplier() {
                return new Promise((resolve) => {
                    const tmpImage = new Image();
                    tmpImage.onload = () => {
                        this.multiplier = tmpImage.width / this.$refs.image.offsetWidth;
                        resolve();
                    };
                    tmpImage.src = this.imageSource;
                });
            },
            addMarker(event) {
                if (this.maxMarkers !== 0 && this.markers.length >= this.maxMarkers) {
                    return;
                }

                const markerBox = event.target.getBoundingClientRect();
                const xPosition = Math.max(0, Math.round(event.clientX - markerBox.left));
                const yPosition = Math.max(0, Math.round(event.clientY - markerBox.top));
                const randomId = RandomStringGenerator(10);

                const marker = {
                    id: randomId,
                    x: Math.round(xPosition * this.multiplier),
                    y: Math.round(yPosition * this.multiplier),
                    type: this.selectedType,
                    text: '',
                    focused: true,
                };

                this.markers.push(marker);

                this.$emit('input', this.markers);
            },
            updateMarker(markerToUpdate) {
                const markerIndex = this.markers.findIndex((marker) => marker.id === markerToUpdate.id);
                if (markerIndex >= 0) {
                    this.markers.splice(markerIndex, 1, { ...this.markers[markerIndex], ...markerToUpdate });

                    this.$emit('input', this.markers);
                }
            },
            removeMarker(id) {
                const markerIndex = this.markers.findIndex((marker) => marker.id === id);
                if (markerIndex >= 0) {
                    this.markers.splice(markerIndex, 1);

                    this.$emit('input', this.markers);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    $type-colors: #2278cf, #cf2267, #cfc922;

    .heat-map-question {
        align-items: flex-start;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        margin: $gutter-m 0 0;
        max-width: 100%;
        position: relative;
        width: 700px;

        // IE11 fix
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &__wrapper {
            max-width: 700px;
            position: relative;
        }

        &__image {
            width: 100%;
        }

        &__container {
            cursor: crosshair;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        &__type-selection {
            display: inline-grid;
            flex-grow: 1;
            grid-auto-flow: row;
            grid-gap: $gutter-m;
            grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
            margin: 0 auto $gutter-s;

            // IE11 fix
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin: -#{$gutter-s} -#{$gutter-s} 0;
            }

            @media (max-width: 767px) {
                margin-left: 0;
            }

            &--disabled {
                opacity: .4;
                pointer-events: none;
            }
        }

        &__type {
            padding-right: 12px;

            // IE11 fix
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                margin: $gutter-s;
            }

            &--active {
                &:first-child {
                    color: nth($type-colors, 1);
                }

                &:nth-child(2) {
                    color: nth($type-colors, 2);
                }

                &:nth-child(3) {
                    color: nth($type-colors, 3);
                }

                /deep/ .heat-map-question {
                    &__type-label {
                        color: #fff;
                    }
                }
            }

            &--inactive {
                color: $color-grey-lighter;

                /deep/ .heat-map-question {
                    &__type-label {
                        color: $color-grey-darker;
                    }

                    &__type-icon {
                        color: $color-grey-dark;
                    }
                }
            }

            &:not(:first-child) {
                // IE11 fix
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    margin-left: $gutter-m;
                }
            }
        }

        &__type-button {
            background-color: currentColor;
            border-radius: 4px;
            display: inline-flex;
            justify-content: center;
            padding: $gutter-s calc(#{$gutter-m} + 10px) $gutter-s $gutter-m;
            position: relative;
            transition: all .2s ease-in-out;
            width: 100%;

            &:hover {
                cursor: pointer;
            }
        }

        &__type-label {
            color: #fff;
            font-size: 12px;
            letter-spacing: 1px;
            margin: 0;
            text-transform: uppercase;
            transition: all .2s ease-in-out;
            word-break: break-word;
        }

        &__type-icon {
            color: currentColor;
            font-size: 30px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateX(50%) translateY(-50%);
            transition: all .2s ease-in-out;
            z-index: 2;
        }

        &__type-shape {
            display: inline-flex;
            position: absolute;
            right: 0;
            transform: translateX(50%) translateY(-50%);
            z-index: 2;

            &--1 {
                background-color: #fff;
                height: 6px;
                top: calc(50% - 3px);
                width: 6px;
            }

            &--2 {
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 6px solid #fff;
                top: calc(50% - 4px);
            }

            &--3 {
                background-color: #fff;
                height: 6px;
                top: calc(50% - 3px);
                transform: translateX(50%) translateY(-50%) rotate(45deg);
                width: 6px;
            }
        }

        &__type-input {
            display: none;
        }

        &__max-markers-label {
            color: #999;
            display: inline-flex;
            flex-shrink: 0;
            font-size: 12px;
            font-style: italic;
            line-height: 36px;
            margin-left: $gutter-s;
            min-height: 36px;
            vertical-align: middle;
        }
    }
</style>

<style lang="scss">
    .heat-map-question {
        * {
            box-sizing: border-box;
        }

        &__type-icon {
            &--background {
                color: #fff !important;

                path {
                    stroke: #fff;
                    stroke-width: 84px;
                }
            }
        }
    }
</style>
